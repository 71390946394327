html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #35655c; */
  background: #f2f2f2;
}

.nav {
  background-color: black;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3em;
  z-index:9999;
}

.nav .content {
  padding: 0em 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
 
}

.nav ul {
  display: block;
}

.nav ul li {
  list-style: none;
}

.nav ul li a {
  line-height: 1em;
  text-decoration: none;
}